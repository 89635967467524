import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import PlayIcon from './PlayIcon';
import PauseIcon from './PauseIcon';

const Video = ({
  className,
  src,
  alt,
  contentType,
  videoRef,
  setPlayState,
  playState,
  isHovered,
}) => {
  let [loaded, setLoaded] = useState(false);
  const [playIconOpacity, setPlayIconOpacity] = useState(0);
  const isPlaying = playState === 'PLAYING';

  const handlePlayClick = (e) => {
    e.stopPropagation(); // prevent triggering swiper on click
    e.preventDefault(); // prevent triggering swiper on click
    videoRef.current.play();
    setPlayState('PLAYING');
  };

  const handlePauseClick = (e) => {
    e.stopPropagation(); // prevent triggering swiper on click
    e.preventDefault(); // prevent triggering swiper on click
    videoRef.current.pause();
    setPlayState('PAUSED');
  };

  const handleMakeIconVisible = () => {
    setPlayIconOpacity(0.9);
  };

  const handleMakeIconInvisible = () => {
    setPlayIconOpacity(0);
  };

  useEffect(() => {
    if (isHovered) {
      handleMakeIconVisible();
    } else {
      handleMakeIconInvisible();
    }
  }, [isHovered, isPlaying]);

  useEffect(() => {
    let timeoutId;

    if (playIconOpacity && isPlaying) {
      timeoutId = setTimeout(() => {
        setPlayIconOpacity(0);
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isPlaying, playIconOpacity]);

  return (
    <Container onMouseMove={handleMakeIconVisible}>
      <VideoComponent
        ref={videoRef}
        className={className}
        alt={alt}
        loaded={loaded}
        onLoadedData={() => setLoaded(true)}
        src={src}
        loop={true}
      >
        <source type={contentType} src={src} />
      </VideoComponent>

      <PlayPauseButtonContainer
        opacity={playIconOpacity}
        onClick={isPlaying ? handlePauseClick : handlePlayClick}
      >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </PlayPauseButtonContainer>
    </Container>
  );
};

const Container = styled.div``;

const PlayPauseButtonContainer = styled.div`
  opacity: ${(props) => props.opacity};
  padding: 24px;
  transition: opacity 0.3s ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
`;

const VideoComponent = styled.video`
  pointer-events: none;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  transition: opacity 0.5s;
`;

export default Video;
