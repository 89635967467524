import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  withRouter,
} from "react-router-dom";
import "./Normalize.css";
import AppActions from "../../actions/AppActions";
import Nav from "../../components/nav/Nav";
import Project from "../project/Project";
import About from "../about/About";
import Colors from "../../consts/Colors";
import AllProjects from "../all-projects/AllProjects";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      frontDoorDisplay: true,
    };

    this.setFrontDoorDisplay = this.setFrontDoorDisplay.bind(this);
  }

  componentDidMount() {
    this.props.onAppLoad({
      loaded: true,
    });
  }

  setFrontDoorDisplay(boolean) {
    this.setState({ frontDoorDisplay: boolean }, window.scrollTo(0, 0));
  }

  render() {
    const isItAbout = window.location.href.includes("about");

    return (
      <>
        {this.props.app.loaded && (
          <>
            <Nav />
            <AppWrapper isItAbout={isItAbout}>
              <Route
                exact
                path={`/`}
                render={() => {
                  return <AllProjects />;
                }}
              />
              <Route
                exact
                path={`/projects/:id`}
                render={() => {
                  return <Project />;
                }}
              />
              <Route
                exact
                path={`/about`}
                render={() => {
                  return <About />;
                }}
              />
            </AppWrapper>
          </>
        )}
      </>
    );
  }
}

const AppWrapper = styled.div`
  background-color: ${(props) =>
    props.isItAbout ? Colors.warmGrey : Colors.lightGrey};
  transition: background-color 1s ease-out;
  position: relative;
`;

const mapStateToProps = (state) => {
  return {
    app: state.app,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAppLoad: (data) => {
      dispatch(AppActions.onAppLoad(data));
    },
  };
};

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export default withRouter(connectedApp);
