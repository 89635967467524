import Utlis from './Utils';
import Devices from './Devices';
import Typography from './Typography';
import Colors from './Colors';

export default {
  boxShadow: 'rgba(0,0,0,0.06) 0px 3px 6px;',

  pageWrapper: `
  padding: 4rem 1rem 0rem 1rem;
  position: relative;
  margin: 0px;

  @media ${Devices.tablet} {
    padding: 0 1rem;
    position: relative;
    margin: 0px 104px;
    border-radius: 10px;

  }

  @media (min-width: 1375px) {
    margin: 0px auto;
    max-width: 1100px;
    }

 `,

  fullHeightMobile: `
  min-height: calc(var(--vh, 1vh) * 100 - 8px);
  `,

  staticFullHeightDesktop: `
    height: calc(var(--vh, 1vh) * 100);
  }`,

  minFullHeightDesktop: `
  min-height: calc(var(--vh, 1vh) * 100);
  `,

  fullHeightCarousel: `
  height: calc((var(--vh, 1vh) * 100) - 5rem);
  `
};
