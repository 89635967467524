import React from 'react';
import styled from 'styled-components';
import { ReactComponent as PauseFilletAltIcon } from '../../assets/svg/pause--filled.svg';

const PauseIcon = () => {
  return (
    <Wrapper>
      <PauseFilletAltIcon />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  svg {
    width: 60px;
    height: 100%;
  }
`;

export default PauseIcon;
