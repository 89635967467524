import React, { useRef, useState } from "react";
import styled from "styled-components";
import { H4 } from "../text-elements/index";
import Devices from "../../consts/Devices";

const CarouselDescription = ({ children, carouselViewIncliningPercentage }) => {
  return (
    <Wrapper carouselViewIncliningPercentage={carouselViewIncliningPercentage}>
      <Text>{children}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  opacity: ${(props) => props.carouselViewIncliningPercentage / 100};
  transition: opacity, 0.5s;
  max-width: 100%;

  @media ${Devices.tablet} {
    max-width: 59%;
  }
`;

const Text = styled(H4)`
  line-height: 1.25;

  p {
    margin-top: 30px !important;
  }

  a {
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #000000;
    color: #000000;
    transition: text-decoration-color, 0.2s;
  }

  a:hover {
    text-decoration-color: #dcff58;
    transition: text-decoration-color, 0.2s;
  }
`;

export default CarouselDescription;
