import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Image from '../media/Image';
import Devices from '../../consts/Devices';
import Video from '../media/Video';

const CarouselImage = ({ isActiveSlide, src, contentType, isHovered }) => {
  const [playState, setPlayState] = useState('PAUSED');
  const videoRef = useRef();
  const windowHeight = window.innerHeight;
  const imageHeight = (windowHeight / 100) * 75 * 2;
  const mediaUrl = src + `?h=${imageHeight.toFixed()}`;
  const isVideo = contentType.includes('video');

  useEffect(() => {
    if (isVideo && !isActiveSlide) {
      videoRef.current.pause();
      setPlayState('PAUSED');
    }
  }, [isActiveSlide, isVideo]);

  if (isVideo) {
    return (
      <div>
        <VideoComp
          src={mediaUrl}
          contentType={contentType}
          videoRef={videoRef}
          isHovered={isHovered}
          playState={playState}
          setPlayState={setPlayState}
        />
      </div>
    );
  }

  return (
    <div>
      <ImageComp src={mediaUrl} />
    </div>
  );
};

const ImageComp = styled(Image)`
  object-fit: cover;
  height: 50vh;

  @media ${Devices.tablet} {
    object-fit: contain;
    height: 75vh;
  }
`;

const VideoComp = styled(Video)`
  object-fit: cover;
  height: 50vh;

  @media ${Devices.tablet} {
    object-fit: contain;
    height: 75vh;
  }
`;

export default CarouselImage;
