import React, { useRef, useState } from "react";
import styled from "styled-components";
import ProjectArrowNavigator from "./project-arrow-navigator/ProjectArrowNavigator";
import Anims from "../../consts/Anims";
import Devices from "../../consts/Devices";

const ProjectArrows = ({
  carouselViewIncliningPercentage,
  carouselViewDecliningPercentage,
}) => {
  const topOrBottom =
    carouselViewDecliningPercentage > carouselViewIncliningPercentage;

  return (
    <>
      {topOrBottom ? (
        <TopArrowWrapper
          carouselViewDecliningPercentage={carouselViewDecliningPercentage}
        >
          <ProjectArrowNavigator topOrBottom={"top"} />
        </TopArrowWrapper>
      ) : (
        <BottomArrowWrapper
          carouselViewDecliningPercentage={carouselViewDecliningPercentage}
        >
          <ProjectArrowNavigator topOrBottom={"bottom"} />
        </BottomArrowWrapper>
      )}
    </>
  );
};

const TopArrowWrapper = styled.div`
  /* opacity: ${(props) =>
    props.carouselViewDecliningPercentage < 100 ? 0 : 1}; */
  ${Anims.enterTop}
  transition: opacity, 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: calc(50% - 60px);
  height: 40px;
  width: 120px;
  bottom: auto;
  top: ${(props) =>
    props.carouselViewDecliningPercentage >= 100
      ? `60px;  transition: top, 0.3s;`
      : `-40px;  transition: top, 0.3s;`};

  @media ${Devices.tablet} {
    top: ${(props) =>
      props.carouselViewDecliningPercentage >= 100
        ? `30px;  transition: top, 0.3s;`
        : `-40px;  transition: top, 0.3s;`};
  }
`;

const BottomArrowWrapper = styled.div`
  /* opacity: ${(props) =>
    props.carouselViewDecliningPercentage < 23 ? 1 : 0}; */
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: calc(50% - 60px);
  height: 40px;
  width: 120px;
  bottom: ${(props) =>
    props.carouselViewDecliningPercentage < 23
      ? `30px;  transition: bottom, 0.3s;`
      : `-40px;  transition: bottom, 0.3s;`};
  top: auto;
`;

export default ProjectArrows;
