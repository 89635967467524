import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NavLink from "./nav-link/NavLink";
import HoveredLine from "../../hovered-line/HoveredLine";

const NavLinks = ({ location }) => {
  const [aboutHovered, setAboutHovered] = useState(false);
  const [projectsHovered, setProjectsHovered] = useState(false);

  useEffect(() => {
    setActiveHovers();
  }, [location]);

  const setActiveHovers = () => {
    if (location === "/" || location.includes("projects")) {
      setProjectsHovered(true);
      setAboutHovered(false);
    } else if (location.includes("about")) {
      setAboutHovered(true);
      setProjectsHovered(false);
    } else {
      setProjectsHovered(false);
      setAboutHovered(false);
    }
  };

  return (
    <Wrapper>
      <NavLink
        className="nav-link"
        target="/about"
        onMouseOver={() => {
          setAboutHovered(true);
        }}
        onMouseOut={() => {
          !location.includes("about") && setAboutHovered(false);
        }}
      >
        {"About".toUpperCase()}
        <HoveredLine hovered={aboutHovered} zIndex={-1} />
      </NavLink>
      <NavLink
        className="nav-link"
        target="/"
        onMouseOver={() => {
          setProjectsHovered(true);
        }}
        onMouseOut={() => {
          !location.includes("projects") &&
            location !== "/" &&
            setProjectsHovered(false);
        }}
      >
        {"Projects".toUpperCase()}
        <HoveredLine hovered={projectsHovered} zIndex={-1} />
      </NavLink>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  transform-origin: bottom left;
  transform: rotate(-90deg) translateY(50%);
  left: 50%;
  display: flex;

  a:first-of-type {
    margin-left: 40px;
  }
`;

export default NavLinks;
