import { SET_APP, SET_ACTIVE_PROJECT, CLEAR_ACTIVE_PROJECT } from './ActionTypes';
import * as contentful from 'contentful';
import axios from 'axios';

const Actions = {
  onAppLoad: (data) => async (dispatch) => {
    const client = contentful.createClient({
      space: 'alas4rccq6km',
      accessToken: process.env.REACT_APP_CONTENTFUL_API_TOKEN
    });

    const platform = () => {
      if (window.innerWidth < 768) {
        return 'mobile';
      } else {
        return 'desktop';
      }
    };

    let projects;
    let featuredProjects;
    let about;

    await client
      .getEntries({
        content_type: 'project'
      })
      .then((entries) => {
        projects = entries.items.map((entry) => {
          return entry.fields;
        });
        featuredProjects = projects.filter((entry) => {
          return entry.featuredOnHomeScreen === true;
        });
      });

    await client
      .getEntries({
        content_type: 'about'
      })
      .then((entries) => {
        about = entries.items.map((entry) => {
          return entry.fields;
        });
      });

    if (projects) {
      dispatch({
        type: SET_APP,
        payload: {
          loaded: true,
          projects: projects,
          featuredProjects: featuredProjects,
          about: about[0],
          device: platform()
        }
      });
    }
  },
  setActiveProject: (data) => async (dispatch) => {
    const foundProject = data.allProjects.filter((project) => {
      return project.slug === data.slug;
    });

    const activeProject = { activeProject: foundProject[0] };
    dispatch({
      type: SET_ACTIVE_PROJECT,
      payload: activeProject
    });
  },
  clearActiveProject: () => async (dispatch) => {
    dispatch({
      type: CLEAR_ACTIVE_PROJECT
    });
  }
};

export default Actions;
