import React, { useRef, useState } from "react";
import styled from "styled-components";
import Colors from "../../../consts/Colors";
import MobileNavLinks from "../nav-links/MobileNavLinks";
import StylePrefabs from "../../../consts/StylePrefabs";

const TopNav = ({}) => {
  return (
    <Wrapper>
      <MobileNavLinks location={window.location.pathname} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* background-color: ${Colors.white}; */
  padding: 1.5rem 0 1rem;
  width: 100vw;
  position: fixed;
  display: flex;
  top: 0rem;
  justify-content: center;
  z-index: 1;
`;

export default TopNav;
