import React, { useRef, useState } from "react";
import styled from "styled-components";
import StylePrefabs from "../../consts/StylePrefabs";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProjectCube from "../../components/project-cube/ProjectCube";
import Devices from "../../consts/Devices";

class AllProjects extends React.Component {
  render() {
    return (
      <Wrapper>
        {this.props.projects.map((projectData, i) => {
          return <ProjectCube key={i} data={projectData} />;
        })}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${StylePrefabs.pageWrapper};
  ${StylePrefabs.minFullHeightDesktop};
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  padding-top: 7rem !important;

  @media ${Devices.tablet} {
    padding-top: 5rem !important;
  }
`;

const mapStateToProps = (state) => {
  return { app: state.app, projects: state.app.projects };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const connectedAllProjects = connect(
  mapStateToProps,
  mapDispatchToProps
)(AllProjects);

export default connectedAllProjects;
