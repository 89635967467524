import React, { useRef, useState } from "react";
import styled from "styled-components";

const RightClickArea = ({ onMouseOver, onMouseOut, onClick }) => {
  return (
    <Wrapper
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    ></Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;

export default RightClickArea;
