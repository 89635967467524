import React, { useRef, useState } from "react";
import styled from "styled-components";
import RightClickArea from "./right-click-area/RightClickArea";
import LeftClickArea from "./left-click-area/LeftClickArea";
import Cursor from "../../cursor/Cursor";
import Devices from "../../../consts/Devices";

const ClickWrapper = ({
  prevClick,
  nextClick,
  setLeftHovered,
  setRightHovered,
}) => {
  return (
    <Wrapper>
      <LeftClickArea
        onClick={() => prevClick()}
        onMouseOver={() => {
          setLeftHovered(true);
        }}
        onMouseOut={() => {
          setLeftHovered(false);
        }}
      />
      <RightClickArea
        onClick={() => nextClick()}
        onMouseOver={() => {
          setRightHovered(true);
        }}
        onMouseOut={() => {
          setRightHovered(false);
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;

  @media ${Devices.tablet} {
    display: flex;
  }
`;

export default ClickWrapper;
