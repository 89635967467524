import React, { useRef, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import StylePrefabs from "../../consts/StylePrefabs";
import { H5, P } from "../../components/text-elements/index";
import Colors from "../../consts/Colors";
import Devices from "../../consts/Devices";

class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoLoaded: false,
    };

    this.handleVideoLoaded = this.handleVideoLoaded.bind(this);
  }

  renderContactDetails(contactEmails, contactNames) {
    return contactEmails.map((contactEmail, index) => {
      const mailTo = `mailto:${contactEmail}`;
      return (
        <>
          <ContactDetailRow key={index}>
            <ContactEmail>
              <a href={mailTo}>{contactEmail}</a>
            </ContactEmail>
            <ContactName>{contactNames[index]}</ContactName>
          </ContactDetailRow>
        </>
      );
    });
  }

  handleVideoLoaded() {
    this.setState({ videoLoaded: true });
  }

  render() {
    let { aboutText, media, contactEmails, contactNames } = this.props.about;
    let parsedDescription = documentToReactComponents(aboutText);

    let video = media.fields.file.url + `?h=120`;

    return (
      <Wrapper>
        <TextWrapper>
          <AboutText>{parsedDescription}</AboutText>
          <ContactDetailsWrapper>
            {this.renderContactDetails(contactEmails, contactNames)}
          </ContactDetailsWrapper>
        </TextWrapper>

        <MediaWrapper videoLoaded={this.state.videoLoaded}>
          <Video
            autoPlay
            loop
            muted
            src={video}
            onCanPlay={() => this.handleVideoLoaded()}
          ></Video>
        </MediaWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${StylePrefabs.pageWrapper}
  ${StylePrefabs.minFullHeightDesktop}
  padding: 6rem 2rem 0rem 2rem !important;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  @media ${Devices.tablet} {
    position: relative;
    display: block;
    ${StylePrefabs.staticFullHeightDesktop}
  }
`;

const TextWrapper = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 2rem;

  @media ${Devices.tablet} {
    position: absolute;
    width: 30vw;
    max-width: 500px;
    height: 40vw;
    max-height: 500px;
    left: 2.5%;
    bottom: 50%;
    transform: translateY(50%);
    margin-top: 0;
    padding-bottom: 0;
  }

  @media ${Devices.laptopM} {
    width: 50vw;
  }
`;

const AboutText = styled(H5)`
  /* text-align: justify !important; */
  margin-bottom: 3rem;

  p {
    margin: 0;
  }
`;

const ContactDetailsWrapper = styled.div``;

const ContactDetailRow = styled.div`
  display: flex;
  /* justify-content: space-between; */
`;

const ContactEmail = styled(P)`
  margin-right: 1rem;

  a {
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #000000;
    color: #000000;
    transition: text-decoration-color, 0.2s;
  }

  a:hover {
    text-decoration-color: #dcff58;
    transition: text-decoration-color, 0.2s;
  }
`;

const ContactName = styled(P)``;

const MediaWrapper = styled.div`
  background-color: ${Colors.acidGreen};
  border-radius: 5px;
  width: 100%;
  position: relative;
  opacity: ${(props) => (props.videoLoaded ? 1 : 0)};
  transition: opacity 2s ease-out;

  @media ${Devices.tablet} {
    position: absolute;
    right: 2.5%;
    bottom: 50%;
    transform: translateY(50%);
    width: 40vw;
    max-width: 500px;
    height: 40vw;
    max-height: 500px;
  }
`;

const Video = styled.video`
  height: 100%;
  width: 100%;
  border-radius: 5px;
`;

const mapStateToProps = (state) => {
  return { app: state.app, about: state.app.about };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const connectedAbout = connect(mapStateToProps, mapDispatchToProps)(About);

export default withRouter(connectedAbout);
