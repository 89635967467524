import React, { useState } from 'react';
import styled from 'styled-components/macro';

const Image = ({ className, src, alt }) => {
  let [loaded, setLoaded] = useState(false);
  return (
    <ImageComponent
      className={className}
      alt={alt}
      loaded={loaded}
      onLoad={() => setLoaded(true)}
      src={src}
      loading="lazy"
    />
  );
};

const ImageComponent = styled.img`
  border-radius: 5px;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  transition: opacity 0.5s;
`;

export default Image;
