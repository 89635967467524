import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Arrow } from "../../assets/svg/Junior_Studio_ARROW_RIGHT.svg";
import Devices from "../../consts/Devices";
import Colors from "../../consts/Colors";

const Cursor = ({
  pos,
  hide,
  lightDark,
  lightDarkOverride,
  hovered,
  carousel,
  left,
}) => {
  // if (window.location.href.includes('projects')) {
  //   debugger;
  // }

  return (
    <Wrapper
      left={left}
      lightDark={lightDark}
      lightDarkOverride={lightDarkOverride}
      hide={hide}
      style={
        pos
          ? {
              top: pos.y,
              left: pos.x,
            }
          : {}
      }
      carousel={carousel}
    >
      <Arrow />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: none;

  @media ${Devices.tablet} {
    display: block;
  }

  pointer-events: none;
  position: fixed;
  z-index: 50;
  transform: translateX(-90%) translateY(-35%)
    ${(props) => (props.left ? "rotate(180deg)" : "")};
  opacity: 0.75;
  margin-top: -10px;
  white-space: nowrap;
  transition: opacity 0.2s ease-out;

  ${(props) =>
    props.hide &&
    `
    transition: opacity 0.2s ease-out;
    opacity: 0;
  `}

  svg {
    width: 60px;
    height: 100%;
    margin-left: 0.5rem;
    position: relative;
    top: 3px;
    path {
      transition: stroke 0.3s ease-out;
      stroke: ${(props) =>
        props.lightDarkOverride
          ? Colors.black
          : props.lightDark
          ? Colors.black
          : Colors.black};
    }
  }
`;

export default Cursor;
