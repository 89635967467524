import React, { useRef, useState } from "react";
import styled from "styled-components";
import LeftNav from "./left-nav/LeftNav";
import RightNav from "./right-nav/RightNav";
import TopNav from "./top-nav/TopNav";
import StylePrefabs from "../../consts/StylePrefabs";
import Devices from "../../consts/Devices";

const Nav = ({}) => {
  return (
    <Wrapper>
      <Mobile>
        <TopNav />
      </Mobile>
      <Desktop>
        <LeftNav />
        <RightNav />
      </Desktop>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* position: absolute;
  width: 100%;
  height: 100%; */
`;

const Mobile = styled.div`
  display: flex;
  position: relative;
  @media ${Devices.laptop} {
    display: none;
  }
`;

const Desktop = styled.div`
  display: none;
  position: relative;
  @media ${Devices.laptop} {
    display: flex;
  }
`;

export default Nav;
