import React, { useRef, useState } from "react";
import styled from "styled-components";
import Colors from "../../../consts/Colors";
import NavLinks from "../nav-links/NavLinks";
import StylePrefabs from "../../../consts/StylePrefabs";

import { ReactComponent as Logo } from "../../../assets/svg/Junior_Studio_LOGO.svg";

const LeftNav = ({}) => {
  let [hovered, setHovered] = useState(false);

  return (
    <Wrapper
      onMouseOver={() => {
        setHovered(true);
      }}
      onMouseOut={() => {
        setHovered(false);
      }}
      hovered={hovered}
      carousel={window.location.pathname.includes("/projects/")}
    >
      <NavLinks location={window.location.pathname} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${StylePrefabs.staticFullHeightDesktop};
  width: 100px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  opacity: ${(props) => (props.hovered || !props.carousel ? 1 : 0.2)};
  transition: opacity, 0.4s ease-out;
  z-index: 1;
  /* background-color: ${Colors.warmGrey}; */
`;

export default LeftNav;
