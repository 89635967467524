import React, { useRef, useState } from "react";
import styled from "styled-components";
import Typography from "../../../consts/Typography";
import Devices from "../../../consts/Devices";

const H4 = ({ children, style, className, fixedSize }) => {
  return (
    <Text style={style} fixedSize={fixedSize} className={className}>
      {children}
    </Text>
  );
};

const Text = styled.span`
  ${Typography.h4}

  ${(props) =>
    props.fixedSize
      ? `font-size: ${Typography.fixedFontSizesMobile.h4}; 
    @media ${Devices.mobileL} {
      font-size: ${Typography.fixedFontSizesDesktop.h4}; 
    }`
      : null}
`;

export default H4;
