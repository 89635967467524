import React, { useRef, useState } from "react";
import styled from "styled-components";
import ProjectCarousel from "../../components/project-carousel/ProjectCarousel";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppActions from "../../actions/AppActions";
import StylePrefabs from "../../consts/StylePrefabs";
import CarouselDescription from "../../components/carousel-description/CarouselDescription";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import CarouselHeader from "../../components/project-carousel/carousel-text/CarouselHeader";
import ProjectArrows from "../../components/project-arrows/ProjectArrows";
import Devices from "../../consts/Devices";

class Project extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      carouselIndex: 0,
      carouselViewDecliningPercentage: 100,
      carouselViewIncliningPercentage: 0,
      // loaded: false
    };

    this.setCarouselIndex = this.setCarouselIndex.bind(this);
    this.headerRef = React.createRef();
  }

  componentDidMount() {
    const slug = this.props.match.params.id;
    this.props.setActiveProject(slug, this.props.app.projects);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 100);
  }

  componentWillUnmount() {
    this.props.clearActiveProject();
  }

  setCarouselIndex(index) {
    this.setState({ carouselIndex: index });
  }

  render() {
    // console.log(`declining: ${this.state.carouselViewDecliningPercentage}`);
    // console.log(`inclining: ${this.state.carouselViewIncliningPercentage}`);
    let parsedDescription, title, images, year;

    if (this.props.activeProject) {
      title = this.props.activeProject.title;
      images = this.props.activeProject.images;
      year = this.props.activeProject.year.split("").slice(0, 4).join("");
      parsedDescription = documentToReactComponents(
        this.props.activeProject.description
      );
    }

    return (
      <>
        {this.props.activeProject ? (
          <Wrapper>
            <ProjectArrows
              carouselViewIncliningPercentage={
                this.state.carouselViewIncliningPercentage
              }
              carouselViewDecliningPercentage={
                this.state.carouselViewDecliningPercentage
              }
            />
            <ProjectCarousel
              device={this.props.app.device}
              activeProject={this.props.activeProject}
              setCarouselIndex={this.setCarouselIndex}
              carouselViewDecliningPercentage={
                this.state.carouselViewDecliningPercentage
              }
              setCarouselPercentageInView={(declining, inclining) => {
                this.setState({
                  carouselViewDecliningPercentage: declining,
                  carouselViewIncliningPercentage: inclining,
                });
              }}
            />
            <CarouselHeader
              carouselInView={this.state.carouselInView}
              caption={this.state.carouselIndex + 1}
              totalImages={images.length}
              title={title}
              year={year}
              location={this.props.activeProject.location}
              client={this.props.activeProject.client}
              carouselViewIncliningPercentage={
                this.state.carouselViewIncliningPercentage
              }
              carouselViewDecliningPercentage={
                this.state.carouselViewDecliningPercentage
              }
            />

            <DetailsAreaWrapper>
              <CarouselDescription
                carouselViewIncliningPercentage={
                  this.state.carouselViewIncliningPercentage
                }
              >
                {parsedDescription}
              </CarouselDescription>
            </DetailsAreaWrapper>
          </Wrapper>
        ) : (
          <p>Loading...</p>
        )}
      </>
    );
  }
}

const Wrapper = styled.div`
  ${StylePrefabs.pageWrapper}
  position: relative;
  padding: 0rem 2rem;
`;

const DetailsAreaWrapper = styled.div`
  min-height: calc(var(--vh, 1vh) * 75);
  display: flex;
  justify-content: flex-end;

  @media ${Devices.tablet} {
    ${StylePrefabs.minFullHeightDesktop}
  }
`;

const mapStateToProps = (state) => {
  return {
    app: state.app,
    activeProject: state.app.activeProject,
    allProjects: state.app.projects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveProject: (slug, allProjects) => {
      const data = { slug: slug, allProjects: allProjects };
      dispatch(AppActions.setActiveProject(data));
    },
    clearActiveProject: () => {
      dispatch(AppActions.clearActiveProject());
    },
  };
};

const connectedProject = connect(mapStateToProps, mapDispatchToProps)(Project);

export default withRouter(connectedProject);
