import { SET_APP, SET_ACTIVE_PROJECT, CLEAR_ACTIVE_PROJECT } from '../actions/ActionTypes';

const initialState = {
  loaded: false,
  projects: null,
  featuredProjects: null,
  activeProject: null,
  about: null,
  device: null
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_APP:
      return {
        ...state,
        ...action.payload
      };
    case SET_ACTIVE_PROJECT:
      return {
        ...state,
        ...action.payload
      };
    case CLEAR_ACTIVE_PROJECT:
      return {
        ...state,
        activeProject: null
      };
    default:
      return state;
  }
}
