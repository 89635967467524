import Devices from './Devices';
import Colors from './Colors';

const title = `
font-family: 'Darker Grotesque', sans-serif;
font-weight: 600;
color: ${Colors.black};
`;

const body = `
font-family: 'Darker Grotesque', sans-serif;
font-weight: 400;
color: ${Colors.black}
`;

// Perfect fourth
const modularScaleDesktop = {
  h1: '4.209em',
  h2: '3.157em',
  h3: '2.369em',
  h4: '1.777em',
  h5: '1.333em',
  p: '1em'
};

const modularScaleMobile = {
  h1: '2.488em',
  h2: '2.074em',
  h3: '1.728em',
  h4: '1.44em',
  h5: '1.2em',
  p: '1em'
};

const ratio = 1.2;
const desktopBaseline = 16;
let mobileBaseline = desktopBaseline - 2;

const fixedFontSizesDesktop = {
  h4: `${desktopBaseline * ratio * ratio}px`,
  h5: `${desktopBaseline * ratio}px`,
  p: `${desktopBaseline}px`
};

const fixedFontSizesMobile = {
  h4: `${mobileBaseline * ratio * ratio}px`,
  h5: `${mobileBaseline * ratio}px`,
  p: `${mobileBaseline}`
};

export default {
  title: title,
  body: body,
  modularScaleDesktop: modularScaleDesktop,
  modularScaleMobile: modularScaleMobile,
  fixedFontSizesDesktop: fixedFontSizesDesktop,
  fixedFontSizesMobile: fixedFontSizesMobile,

  h1: `
  ${title}
  font-size: ${modularScaleMobile.h1};
  @media ${Devices.mobileL} {
    font-size: ${modularScaleDesktop.h1}; 
    }
  `,

  h2: `
  ${title}
  font-size: ${modularScaleMobile.h2};
  @media ${Devices.mobileL} {
    font-size: ${modularScaleDesktop.h2}; 
    }
  `,

  h3: `
  ${title}
  font-size: ${modularScaleMobile.h3};
  @media ${Devices.mobileL} {
    font-size: ${modularScaleDesktop.h3}; 
    }
  `,

  h4: `
  ${title}
  font-size: ${modularScaleMobile.h4};
  @media ${Devices.mobileL} {
    font-size: ${modularScaleDesktop.h4}; 
    }
  `,

  h5: `
  ${title}
  font-size: ${modularScaleMobile.h5};
  @media ${Devices.mobileL} {
    font-size: ${modularScaleDesktop.h5}; 
    }
  `,

  p: `
  ${body}
  font-size: ${modularScaleMobile.p};
  @media ${Devices.mobileL} {
    font-size: ${modularScaleDesktop.p}; 
    }
  `
};
