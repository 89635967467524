import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NavLink from "./nav-link/NavLink";
import HoveredLine from "../../hovered-line/HoveredLine";
import { ReactComponent as MobileLogo } from "../../../assets/svg/JV_LOGO.svg";

const NavLinks = ({ location }) => {
  const [aboutHovered, setAboutHovered] = useState(false);
  const [projectsHovered, setProjectsHovered] = useState(false);

  useEffect(() => {
    setActiveHovers();
  }, [location]);

  const setActiveHovers = () => {
    if (location === "/" || location.includes("projects")) {
      setProjectsHovered(true);
      setAboutHovered(false);
    } else if (location.includes("about")) {
      setAboutHovered(true);
      setProjectsHovered(false);
    } else {
      setProjectsHovered(false);
      setAboutHovered(false);
    }
  };

  return (
    <Wrapper>
      <Left>
        <NavLink
          className="nav-link"
          target="/"
          onMouseOver={() => {
            setProjectsHovered(true);
          }}
          onMouseOut={() => {
            !location.includes("projects") &&
              location !== "/" &&
              setProjectsHovered(false);
          }}
        >
          {"Projects".toUpperCase()}
          <HoveredLine hovered={projectsHovered} zIndex={-1} />
        </NavLink>
      </Left>
      <LinkComponent href="/">
        <MobileLogo />
      </LinkComponent>
      <Right>
        <NavLink
          className="nav-link"
          target="/about"
          onMouseOver={() => {
            setAboutHovered(true);
          }}
          onMouseOut={() => {
            !location.includes("about") && setAboutHovered(false);
          }}
        >
          {"About".toUpperCase()}
          <HoveredLine hovered={aboutHovered} zIndex={-1} />
        </NavLink>
      </Right>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  width: 100%;

  a:first-of-type {
  }
`;

const Left = styled.div`
  width: 50%;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  margin-left: 2rem;
`;

const LinkComponent = styled.a`
  position: absolute;
  top: 29px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;

  svg {
    height: 16px;
    width: auto;
  }
`;

const Right = styled.div`
  width: 50%;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  margin-right: 2rem;
`;

export default NavLinks;
