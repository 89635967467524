import React, { useRef, useState } from "react";
import styled from "styled-components";
import Typography from "../../../consts/Typography";

const H3 = ({ children, style, className }) => {
  return (
    <Text style={style} className={className}>
      {children}
    </Text>
  );
};

const Text = styled.span`
  ${Typography.h3}
`;

export default H3;
