import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Arrow } from "../../../assets/svg/Junior_Studio_ARROW_DOWN.svg";
import Colors from "../../../consts/Colors";
import Devices from "../../../consts/Devices";

const ProjectArrowNavigator = ({ topOrBottom, scrollModifier }) => {
  if (scrollModifier) {
    scrollModifier = scrollModifier;
  } else {
    scrollModifier = 0.9;
  }

  const exploreProjectsCTAOnClick = (topOrBottom) => {
    if (topOrBottom === "top") {
      window.scroll({
        top: window.innerHeight / scrollModifier,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <Wrapper
      onClick={() => exploreProjectsCTAOnClick(topOrBottom)}
      topOrBottom={topOrBottom}
    >
      <Arrow />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  transform: ${(props) =>
    props.topOrBottom === "top" ? "rotate(0deg)" : "rotate(180deg)"};
  svg {
    cursor: pointer;
    width: 60px;
    height: 100%;
    margin-left: 0rem;
    position: relative;
    top: 3px;

    @media ${Devices.tablet} {
      margin-left: 0.5rem;
    }

    path {
      transition: stroke 0.3s ease-out;
      stroke: ${Colors.black};
    }
  }
`;

export default ProjectArrowNavigator;
