import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import CarouselImage from '../carousel-image/CarouselImage';
import ClickWrapper from './click-wrapper/ClickWrapper';
import StylePrefabs from '../../consts/StylePrefabs';
import Colors from '../../consts/Colors';
import Cursor from '../cursor/Cursor';
import Devices from '../../consts/Devices';

class ProjectCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselRef: null,
      hovered: false,
      mousePos: null,
      leftHovered: false,
      rightHovered: false,
      currentSlide: 0,
    };

    this.nextClick = this.nextClick.bind(this);
    this.prevClick = this.prevClick.bind(this);
    this.trackScrolling = this.trackScrolling.bind(this);
    this.carouselOffSetCalc = this.carouselOffSetCalc.bind(this);
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    console.log('unmounting');
    document.removeEventListener('scroll', this.trackScrolling);
  }

  nextClick = () => {
    let currentSlide = this.state.currentSlide;
    let nextSlide = currentSlide + 1;
    let finalSlide = this.props.activeProject.images.length;

    if (nextSlide <= finalSlide - 1) {
      this.setState({ currentSlide: nextSlide }, () => this.state.carouselRef.slideNext());
    } else {
      window.scroll({
        top: window.innerHeight,
        left: 0,
        behavior: 'smooth',
      });
    }

    // debugger
    // console.log(this.state.currentSlide);
  };

  prevClick = () => {
    let currentSlide = this.state.currentSlide;
    let prevSlide = currentSlide - 1;

    if (prevSlide >= 0) {
      this.setState({ currentSlide: prevSlide }, () => this.state.carouselRef.slidePrev());
    }
  };

  trackScrolling = () => {
    if (this.wrapperRef.current) {
      this.carouselOffSetCalc();
    }
  };

  carouselOffSetCalc() {
    let { height, top } = this.wrapperRef.current.getBoundingClientRect();
    const difference = height + top;
    let decliningPercentage = (difference / height) * 100;
    let incliningPercentage = 100 - (difference / height) * 100;

    if (decliningPercentage > 100) {
      // decliningPercentage = 100;
    } else if (decliningPercentage < 0) {
      decliningPercentage = 0;
    }

    if (incliningPercentage > 100) {
      incliningPercentage = 100;
    } else if (incliningPercentage < 0) {
      incliningPercentage = 0;
    }

    this.props.setCarouselPercentageInView(decliningPercentage, incliningPercentage);
  }

  render() {
    const { images } = this.props.activeProject;

    const params = {
      initialSlide: this.state.currentSlide,
      allowTouchMove: true,
      slidesPerView: 1.1,
      spaceBetween: 10,
      speed: 400,
      centeredSlides: true,
      breakpoints: {
        768: {
          allowTouchMove: false,
          slidesPerView: 1,
        },
      },
      on: {
        slideChange: () => {
          this.state.carouselRef && this.props.setCarouselIndex(this.state.carouselRef.activeIndex);
        },
      },
    };

    return (
      <Wrapper>
        <CarouselAreaWrapper
          onMouseOver={(event) => {
            this.setState({ hovered: true });
            if (!this.state.mousePos) {
              this.setState({
                mousePos: {
                  x: event.clientX,
                  y: event.clientY,
                },
              });
            }
          }}
          onMouseOut={() => {
            this.setState({ hovered: false });
          }}
          onMouseMove={(event) => {
            if (this.state.hovered) {
              this.setState({
                mousePos: {
                  x: event.clientX,
                  y: event.clientY,
                },
              });
            } else {
              this.setState({
                mousePos: null,
              });
            }
          }}
        >
          <Cursor
            left={this.state.leftHovered && !this.state.rightHovered}
            pos={this.state.mousePos}
            hide={!this.state.hovered}
            hovered={this.state.hovered}
          />
          <Swiper
            getSwiper={(swiperEl) => {
              this.setState({ carouselRef: swiperEl });
            }}
            {...params}
          >
            {images.map((image, i) => {
              return (
                <CarouselItemWrapper
                  key={i}
                  carouselViewDecliningPercentage={this.props.carouselViewDecliningPercentage}
                >
                  <CarouselImageWrapper ref={this.wrapperRef}>
                    <ClickWrapper
                      prevClick={this.prevClick}
                      nextClick={this.nextClick}
                      setRightHovered={(bool) => this.setState({ rightHovered: bool })}
                      setLeftHovered={(bool) => this.setState({ leftHovered: bool })}
                    />
                    <CarouselImage
                      src={image.fields.image.fields.file.url}
                      contentType={image.fields.image.fields.file.contentType}
                      isActiveSlide={i === this.state.currentSlide}
                      isHovered={this.state.hovered}
                    />
                  </CarouselImageWrapper>
                </CarouselItemWrapper>
              );
            })}
          </Swiper>
        </CarouselAreaWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 25vh 0rem 0rem;

  @media ${Devices.mobileM} {
    padding: 20vh 0rem 0rem;
  }

  @media ${Devices.tablet} {
    padding: 15vh 0rem 0rem;
  }

  /* position: absolute; */
  /* top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%); */
`;

const CarouselAreaWrapper = styled.div`
  cursor: none;

  .swiper-container {
    border-radius: 5px;
  }

  /* ${StylePrefabs.fullHeightCarousel}; */
`;

const CarouselItemWrapper = styled.div`
  ${(props) =>
    props.carouselViewDecliningPercentage > 25
      ? `
        opacity: ${props.carouselViewDecliningPercentage / 100};
        transition: opacity, 0.5s;
        `
      : `
        opacity: 0;
        transition: opacity, 0.5s;
        `}
  display: flex;
  align-items: center;

  height: 55vh;

  @media ${Devices.mobileM} {
    height: 65vh;
  }

  @media ${Devices.tablet} {
    height: 75vh;
  }
`;

const CarouselImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ProjectCarousel;
