import React, { useRef, useState } from "react";
import styled from "styled-components";
import { H4, H5, P } from "../../text-elements/index";
import Devices from "../../../consts/Devices";

const CarouselHeader = ({
  caption,
  totalImages,
  title,
  year,
  location,
  client,
  carouselViewIncliningPercentage,
  carouselViewDecliningPercentage,
}) => {
  return (
    <Wrapper>
      <LeftWrapper>
        <CarouselTitle>{title}</CarouselTitle>
        <Client
          carouselViewIncliningPercentage={carouselViewIncliningPercentage}
        >
          {client}
        </Client>
        <YearAndLocation
          carouselViewIncliningPercentage={carouselViewIncliningPercentage}
        >
          <Year>{year}</Year>. <Location>{location}</Location>
        </YearAndLocation>
      </LeftWrapper>
      <RightWrapper>
        <CarouselCaption
          carouselViewDecliningPercentage={carouselViewDecliningPercentage}
        >
          {caption} / {totalImages}
        </CarouselCaption>
      </RightWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  /* top: 30px; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media ${Devices.tablet} {
    position: sticky;
    top: 30px;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  @media ${Devices.tablet} {
    max-width: 41%;
  }
`;

const CarouselTitle = styled(H4)`
  line-height: 1.25 !important;
`;

const Client = styled(P)`
  opacity: ${(props) => props.carouselViewIncliningPercentage / 100};
  transition: opacity, 0.5s;
`;

const Location = styled(P)``;

const Year = styled(P)``;

const YearAndLocation = styled.div`
  opacity: ${(props) => props.carouselViewIncliningPercentage / 100};
  transition: opacity, 0.5s;
`;

const RightWrapper = styled.div``;

const CarouselCaption = styled(P)`
  ${(props) =>
    props.carouselViewDecliningPercentage > 25
      ? `
        opacity: ${props.carouselViewDecliningPercentage / 100};
        transition: opacity, 0.5s;
        `
      : `
        opacity: 0;
        transition: opacity, 0.5s;
        `}
  text-align: right;
`;

export default CarouselHeader;
