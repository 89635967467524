import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { P, H5, H4 } from "../../../text-elements/index";
import Typography from "../../../../consts/Typography";
import Devices from "../../../../consts/Devices";

const NavLink = ({ className, target, children, onMouseOver, onMouseOut }) => {
  return (
    <LinkComponent
      className={className}
      to={target}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <Text fixedSize={true}>{children}</Text>
    </LinkComponent>
  );
};

const LinkComponent = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;

  @media ${Devices.laptop} {
    margin-left: 80px;
  }
`;

const Text = styled(H4)``;

export default NavLink;
