import React from 'react';
import styled from 'styled-components';
import { ReactComponent as PlayFilledAltIcon } from '../../assets/svg/play--filled--alt.svg';

const PlayIcon = () => {
  return (
    <Wrapper>
      <PlayFilledAltIcon />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  svg {
    width: 60px;
    height: 100%;
  }
`;

export default PlayIcon;
