import React, { useRef, useState } from "react";
import styled from "styled-components";
import Colors from "../../consts/Colors";
import _ from "lodash";
import { H5 } from "../text-elements/index";
import Interweave from "interweave";
import { Link } from "react-router-dom";
import Anims from "../../consts/Anims";
import Devices from "../../consts/Devices";

class AllProjectsProject extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
      randomTimeValue: null,
      imageLoaded: false,
    };
  }

  componentDidMount() {
    this.setState({ randomTimeValue: this.timeTakenRandomiser() });
  }

  titleArrayCreator = (title) => {
    let finalArray = [];
    let split = title.split("");
    let lettersPerFace = split.length / 2;
    let chunked = _.chunk(split, lettersPerFace);
    if (chunked.length > 2) {
      chunked[1].push(chunked[2][0]);
      chunked.pop();
    }
    for (let chunk of chunked) {
      let newChunk = [];
      for (let letter of chunk) {
        newChunk.push(`<i>${letter}</i>`);
      }

      finalArray.push(newChunk.join(""));
    }
    return finalArray;
  };

  timeTakenRandomiser = () => {
    const min = 10;
    const max = 17;
    return Math.floor(Math.random() * (+max - +min)) + +min;
  };

  imageSrcCreator = (images) => {
    let srcArray = [];
    let newImages = images.slice(0, 4);
    for (let image of newImages) {
      srcArray.push(`${image.fields.image.fields.file.url}?w=200`);
    }
    return srcArray;
  };

  render() {
    const { images, title, slug } = this.props.data;

    let titleArray = this.titleArrayCreator(title);

    let imagesArray = this.imageSrcCreator(images);

    return (
      <Wrapper hovered={this.state.hovered}>
        <Link to={`/projects/${slug}`}>
          <Cube
            onMouseOver={() => {
              this.setState({ hovered: true });
            }}
            onMouseOut={() => {
              this.setState({ hovered: false });
            }}
            hovered={this.state.hovered}
            timeTaken={this.state.randomTimeValue}
          >
            <CubeInner hovered={this.state.hovered}>
              <Front hovered={this.state.hovered}>
                <CubeImage
                  src={imagesArray[0]}
                  hovered={this.state.hovered}
                  imageLoaded={this.state.imageLoaded}
                  onLoad={() => this.setState({ imageLoaded: true })}
                />
                <CubeText hovered={this.state.hovered}>
                  <Interweave content={titleArray[0]} />
                </CubeText>
              </Front>
              <Back hovered={this.state.hovered}>
                <CubeImage
                  src={imagesArray[1]}
                  hovered={this.state.hovered}
                  imageLoaded={this.state.imageLoaded}
                  onLoad={() => this.setState({ imageLoaded: true })}
                />
                <CubeText hovered={this.state.hovered}>
                  <Interweave content={titleArray[0]} />
                </CubeText>
              </Back>
              <Top></Top>
              <Bottom></Bottom>
              <Left hovered={this.state.hovered}>
                <CubeImage
                  src={imagesArray[2]}
                  hovered={this.state.hovered}
                  imageLoaded={this.state.imageLoaded}
                  onLoad={() => this.setState({ imageLoaded: true })}
                />
                <CubeText hovered={this.state.hovered}>
                  <Interweave content={titleArray[1]} />
                </CubeText>
              </Left>
              <Right hovered={this.state.hovered}>
                <CubeImage
                  src={imagesArray[3]}
                  hovered={this.state.hovered}
                  imageLoaded={this.state.imageLoaded}
                  onLoad={() => this.setState({ imageLoaded: true })}
                />
                <CubeText hovered={this.state.hovered}>
                  <Interweave content={titleArray[1]} />
                </CubeText>
              </Right>
            </CubeInner>
          </Cube>
        </Link>
      </Wrapper>
    );
  }
}

const cubeInnerFace = `
position: absolute;
width: 200px;
height: 170px;
background-color: ${Colors.acidGreen};
display: flex;
align-items: center;
justify-content: center;
background-size: cover;
`;

const Wrapper = styled.div`
  ${Anims.fadeIn}
  justify-content: center;
  display: flex;
  width: 100%;
  height: 170px;
  perspective: 800px;
  perspective-origin: 50% 100px;
  margin-bottom: 4rem;

  @media ${Devices.tablet} {
    width: 33%;
  }
`;

const Cube = styled.div`
  position: relative;
  width: 200px;
  transform-style: preserve-3d;
  animation: spin ${(props) => props.timeTaken}s infinite linear;

  @keyframes spin {
    from {
      transform: rotateY(360deg);
    }
    to {
      transform: rotateY(0deg);
    }
  }
`;

const CubeInner = styled.div`
  transform-style: preserve-3d;
  transition: transform 15s;
  ${(props) => (props.hovered ? `transform: rotateY(180deg) !important;` : ``)}
`;

const CubeImage = styled.img`
  position: absolute;
  object-fit: cover;
  border-radius: 0px;
  opacity: ${(props) => (props.hovered || !props.imageLoaded ? 0 : 1)};
  transition: opacity 0.3s;
  width: 100%;
  height: 100%;
`;

const CubeText = styled(H5)`
  z-index: 5;
  color: ${(props) => (props.hovered ? Colors.black : Colors.acidGreen)};
  transition: color 0.4s;
  display: block;
  width: 100%;
  span {
    display: flex;
    justify-content: space-around;
    i {
      font-style: normal;
    }
  }
`;

const Front = styled.div`
  transform: translateZ(100px);
  ${cubeInnerFace};
`;

const Back = styled.div`
  transform: translateZ(-100px) rotateY(180deg);
  ${cubeInnerFace}
`;

const Top = styled.div`
  transform: rotateX(-90deg) translateY(-100px);
  transform-origin: top center;
  ${cubeInnerFace}
`;

const Bottom = styled.div`
  transform: rotateX(90deg) translateY(100px);
  transform-origin: bottom center;
  ${cubeInnerFace}
`;

const Left = styled.div`
  transform: rotateY(270deg) translateX(-100px);
  transform-origin: center left;
  ${cubeInnerFace}
`;

const Right = styled.div`
  transform: rotateY(-270deg) translateX(100px);
  transform-origin: top right;
  ${cubeInnerFace}
`;

export default AllProjectsProject;
